// @flow
import { graphql } from "gatsby";
// @flow
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import LearnMore from "../components/LearnMore";
import Page from "../components/Page";
import Section from "../components/Section";
import CheckMark from "../icons/Check";
import ThumbUp from "../icons/ThumbUp";
import { MEDIUM_BREAKPOINT, query } from "../style";

import type { ImageSharp, ImageType } from "../types";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const { mainImage, secondImage, thirdImage } = this.props.data;
    return (
      <Page title="Refer a patient">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Refer a patient"
          subtitle="We know that making a referral decision can be difficult. You can trust us to uphold the high quality of care your patient deserves, while making the process as streamlined as possible for you and your staff."
          cta="Give us a call"
        />
        <StyledSection color="#e0ecfa">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem"
            }}
          >
            <ThumbUp />
            <TopTitle>What you can expect</TopTitle>
          </div>
          <div
            style={{
              maxWidth: 850,
              margin: "auto",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <Wrap>
              <TitleWrap>
                <CheckMark />
                <Title>Personalized care</Title>
              </TitleWrap>
              <PhilBody>
                Our commitment is to review the information, make an appropriate
                diagnosis, and create a personalized treatment plan for every
                individual we see. Not every herniated disk is the same, and not
                every person is the same. We don't hand out cookie cutter
                treatments.
              </PhilBody>
            </Wrap>
            <Wrap>
              <TitleWrap>
                <CheckMark odd />
                <Title>A prompt appointment</Title>
              </TitleWrap>
              <PhilBody>
                If you're sending us a patient, we'll do everything in our power
                to get them in with a doctor in a timely manner. We've heard
                horror stories about patients waiting months for an appointment,
                that will not be the case with us.
              </PhilBody>
            </Wrap>

            <Wrap>
              <TitleWrap>
                <CheckMark />
                <Title>Coordination of care</Title>
              </TitleWrap>
              <PhilBody>
                We are grateful when doctors send patients our way. Beyond
                providing the highest quality health care, we will do everything
                we can to make it easy for you and your staff, from coordination
                of medical records to helping the patient with their insurance.
              </PhilBody>
            </Wrap>
            <Wrap>
              <TitleWrap>
                <CheckMark odd />
                <Title>A mutlidisciplinary approach</Title>
              </TitleWrap>
              <PhilBody>
                The Spine Institute of Idaho is just that: an Institute. We do
                more than just surgery, offering everything from physical
                medicine to physiatry and pain managment. We will do whatever we
                can to make sure your patient is informed, confident with their
                personalized treatment plan, and is set up to be successful
              </PhilBody>
            </Wrap>
            <Wrap>
              <TitleWrap>
                <CheckMark />
                <Title>Closing the referral loop</Title>
              </TitleWrap>
              <PhilBody>
                You trusted us with your patient, and as such, we will commit to
                providing them timely care, and expeditiously getting
                information back to you. We will automatically forward you a
                copy of the new patient consult notes, giving you the knowledge
                you need in follow-up, and the confidence to know we will meet
                your needs.
              </PhilBody>
            </Wrap>
            <Wrap>
              <TitleWrap>
                <CheckMark odd />
                <Title>Authorizations</Title>
              </TitleWrap>
              <PhilBody>
                Don't worry, we got it covered. We know authorizations can be a
                pain, nobody can deny that. We want to make it as easy for you
                and your patients as possible. That's why we have a whole
                department just to handle authorizations.
              </PhilBody>
            </Wrap>
          </div>
        </StyledSection>
        <LearnMore body="Whether you’re just hearing about us, or have referred patients before, there’s a lot to know. If you have a question at any time, don’t hesitate to call!" />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const BelowTitle = styled.p`
  && {
    font-size: 17px;
    max-width: 650px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TopTitle = styled.h1`
  && {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

const StyledSection = styled(Section)`
  && {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 60px;
    ${query(MEDIUM_BREAKPOINT)`
    padding-top: 250px;
    /* margin-top: 60px; */
  `};
  }
`;
const Wrap = styled.div`
  && {
    padding: 8px;
    flex: 1 0 290px;
  }
`;

const Title = styled.h3`
  && {
    margin-bottom: 1rem;
    margin: 0;
    margin-left: 10px;
  }
`;

const TitleWrap = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

const PhilBody = styled.p`
  && {
  }
`;

export const pageQuery = graphql`
  query referralPhotos {
    mainImage: file(relativePath: { eq: "square/sam.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/surgery-3.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/injections.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
  }
`;
