//@flow
import React from 'react';
import { purple } from '../style/colors';

export default ({
  className,
  style,
  ...rest
}: {
  className?: string,
  style?: any,
  rest?: $Rest<any, any>,
}) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <path
      fill="#6772E5"
      id="path0_fill"
      d="M36 0a36 36 0 1 1 0 72 36 36 0 0 1 0-72z"
    />
    <path
      fill="#C4F0FF"
      transform="translate(21 18)"
      d="path1_fill"
      fillRule="evenodd"
      d="M0 30h5.82V12H0v18zm32-16.5c0-1.65-1.3-3-2.9-3H19.9l1.38-6.86.05-.48a2.3 2.3 0 0 0-.64-1.59L19.16 0 9.59 9.88A2.98 2.98 0 0 0 8.73 12v15c0 1.65 1.3 3 2.9 3h13.1c1.2 0 2.24-.75 2.67-1.83l4.4-10.58c.13-.34.2-.7.2-1.09v-2.87l-.01-.01.01-.12z"
    />
  </svg>
);
